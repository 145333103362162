class Submenu {
  constructor(button, submenu, el, submenuOptions) {
    this.el = el
    this.button = button
    this.submenu = submenu
    this.links = [...submenu.querySelectorAll('a')]
    this.list = submenu.querySelector('[data-submenu-list]')
    this.submenus = [...el.querySelectorAll('[data-submenu]')]
    this.submenuButtons = [...el.querySelectorAll('[data-submenu-btn]')]
    this.submenuOptions = submenuOptions

    /* Events */
    this.boundOnClick = this.onClick.bind(this)
  }

  toggleSubmenu() {
    if (this.submenu.dataset.open === 'true') {
      this.closeSubmenu(this.submenu, this.button, this.links)
    } else {
      this.closeOthers()
      this.openSubmenu(this.submenu, this.button, this.links)
    }
  }

  setSubmenuEndHeight() {
    const height = this.list.clientHeight

    this.submenu.style.height = `${height}px`
  }

  openSubmenu(submenu, button, links) {
    submenu.setAttribute('data-open', 'true')
    button.setAttribute('aria-expanded', 'true')
    submenu.hidden = false

    if (this.submenuOptions.useHeight) {
      this.setSubmenuEndHeight()
    }

    setTimeout(() => {
      submenu.style.opacity = 1
      submenu.classList.add('is-open')
      links.forEach((el) => {
        el.setAttribute('tabindex', '0')
      })
    }, 100)
  }

  closeSubmenu(submenu, button, links) {
    submenu.style.opacity = 0

    if (this.submenuOptions.useHeight) {
      submenu.style.height = '0px'
    }

    submenu.classList.remove('is-open')

    setTimeout(() => {
      submenu.hidden = true
      submenu.setAttribute('data-open', 'false')
      button.setAttribute('aria-expanded', 'false')
      links.forEach((el) => {
        el.setAttribute('tabindex', '-1')
      })
    }, 300)
  }

  closeOthers() {
    const submenusToClose = this.submenus.filter((el) => el !== this.submenu)

    submenusToClose.forEach((submenu) => {
      if (submenu.dataset.open === 'true') {
        const button = submenu.previousElementSibling
        const links = [...submenu.querySelectorAll('a')]

        this.closeSubmenu(submenu, button, links || [])
      }
    })
  }

  onClick(e) {
    if (!e.target.dataset.submenuButton) return

    this.toggleSubmenu()
  }

  init() {
    this.closeSubmenu(this.submenu, this.button, this.links)
    this.button.addEventListener('click', this.boundOnClick)
  }

  destroy() {
    this.openSubmenu(this.submenu, this.button, this.links)
    this.button.removeEventListener('click', this.boundOnClick)
  }
}

export default Submenu
