const initPageTransitions = () => {
  if (
    'IntersectionObserver' in window &&
    'IntersectionObserverEntry' in window &&
    'intersectionRatio' in window.IntersectionObserverEntry.prototype
  ) {
    document.body.classList.add('has-observer')
    import('../helpers/pageTransitions.js').then((obj) => {
      const revealOnEnter = obj.default
      revealOnEnter()
    })
  } else {
    document.body.classList.add('no-observer')
  }
}

export default initPageTransitions
