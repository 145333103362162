const createBundle = (selector, fileName) => {
  const elements = [
    ...document.querySelectorAll(`[data-behaviour="${selector}"]`),
  ]

  elements.forEach((element) => {
    import(`../components/${fileName}.js`).then((component) => {
      component.render(element)
    })
  })
}

const loadBundles = (bundles) => {
  bundles.forEach(({ selector, fileName }) => createBundle(selector, fileName))

  if (document.querySelector('.share-btn')) {
    import('share-buttons').then(() => console.log('share'))
  }
}

export default loadBundles
