class ThemeSwitcher {
  constructor(el) {
    this.el = el
    this.className = 'low-energy'

    this.onLoad()
    el.addEventListener('click', this.onClick.bind(this))
  }

  onLoad() {
    if (localStorage.getItem(this.className) === 'true') {
      this.turnOn()
    }
  }

  onClick(e) {
    e.preventDefault()
    if (document.body.classList.contains(this.className)) {
      this.turnOff()
    } else {
      this.turnOn()
    }
  }

  turnOn() {
    document.body.classList.add(this.className)
    localStorage.setItem(this.className, 'true')
  }

  turnOff() {
    document.body.classList.remove(this.className)
    localStorage.setItem(this.className, 'false')
  }
}

export default ThemeSwitcher
