import throttle from 'lodash.throttle'

class HeaderScroll {
  constructor(el) {
    this.el = el
    this.isScrolled = false

    this.throttledScroll = throttle(this.onScroll.bind(this), 10)
  }

  onScroll() {
    if (window.scrollY > 200 && !this.isScrolled) {
      document.body.classList.add('is-scrolled')
      this.isScrolled = true
    } else if (window.scrollY <= 200 && this.isScrolled) {
      document.body.classList.remove('is-scrolled')
      this.isScrolled = false
    }
  }

  init() {
    window.addEventListener('scroll', this.throttledScroll)
  }

  destroy() {
    window.removeEventListener('scroll', this.throttledScroll)
  }
}

export default HeaderScroll
