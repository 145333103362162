class IndicateScroll {
  constructor(el) {
    this.el = el
    this.listTotalWidth = el.clientWidth - 64
    this.listChildren = document.querySelectorAll('.top-bar-navigation-list li')

    this.enableScrollIndicator()
  }

  enableScrollIndicator() {
    if (this.navChildrenWidth() > this.listTotalWidth) {
      this.el.classList.add('scroll-visible')
    }
  }

  navChildrenWidth() {
    let childrenTotalWidth = 0

    this.listChildren.forEach((item) => {
      childrenTotalWidth += Number(item.clientWidth)
    })

    return childrenTotalWidth
  }
}

export default IndicateScroll
