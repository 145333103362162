import Menu from './components/burgerMenu'
import HeaderSearch from './components/headerSearch'
import HeaderScroll from './components/headerScroll'
import { minDesktop } from '../../helpers/resize'

class MenuDropdown {
  constructor(el) {
    this.burgerMenuInitialized = false
    this.searchInitialized = false
    this.menu = new Menu(el)
    this.search = new HeaderSearch(el, {
      onSearchOpenCallback: this.closeOpenMenus.bind(this),
    })
    this.scroller = new HeaderScroll(el)

    minDesktop.addEventListener('change', this.onResize.bind(this))

    /* Initialize */
    this.initMenu()
    this.search.initSearch()
    this.scroller.init()
  }

  closeOpenMenus() {
    this.menu.submenuInstances.forEach((submenu) => {
      submenu.close()
    })

    if (!minDesktop.matches) {
      this.menu.close()
    }
  }

  onResize() {
    this.initMenu()
  }

  initMenu() {
    document.body.classList.add('has-js-menu')

    if (minDesktop.matches && !this.burgerMenuInitialized) {
      this.menu.initSubmenus({
        useHeight: false,
      })
    }

    if (minDesktop.matches && this.burgerMenuInitialized) {
      this.menu.destroy()
      this.menu.initSubmenus({
        useHeight: false,
      })
      this.burgerMenuInitialized = false
    } else if (!minDesktop.matches && !this.burgerMenuInitialized) {
      this.menu.destroySubmenus()
      this.menu.init()
      this.burgerMenuInitialized = true
    }
  }
}

export default MenuDropdown
